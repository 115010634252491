import { Component } from 'preact';
import { PAGE_NAMES } from '../../../config/i18n/de_page_names';
import React from "preact/compat";

export default class AboutUsDe extends Component {

    componentDidMount() {
        document.title = PAGE_NAMES.ABOUT_US + ' - ' + PAGE_NAMES.HES;
    }

    render() {
        return ([
            <main className="container tracking-details static-content">
                <div className="has-text-centered is-hidden-touch">
                    <section className="hero">
                        <div className="hero-body">
                            <div className="tracking-container">
                                <h1 className="title has-text-white is-size-2">
                                    Über uns
                                </h1>
                            </div>
                        </div>
                    </section>
                </div>
                <div className="columns main-container about-us">
                    <div className="column is-10-desktop is-offset-1-desktop">
                        <div className="has-text-grey-darker has-text-centered is-hidden-desktop">
                            <section className="hero">
                                <div className="hero-body">
                                    <div className="tracking-container">
                                        <h1 className="title is-size-2-tablet is-size-4-mobile">
                                            Über uns
                                        </h1>
                                    </div>
                                </div>
                            </section>
                        </div>

                        <div className="">
                            <div className="">
                                <figure className="image">
                                    <img alt="Headerbild" src="/assets/about/Headerbild.jpg"/>
                                </figure>
                            </div>
                            <div className="">
                                <h1 className="has-text-weight-bold is-size-5">Hermes Einrichtungs Service – der
                                    Marktführer im 2-Mann-Handling</h1>
                                <p>
                                    Der Hermes Einrichtungs Service beliefert jährlich rund sieben Millionen Endkunden
                                    mit Möbeln, Elektrogroßgeräten und anderen schweren, sensiblen Gütern und ist damit
                                    Marktführer im 2-Mann-Handling.
                                </p>
                                <p>
                                    Der Hermes Einrichtungs Service ist Teil der weltweit agierenden Otto Group und
                                    verfügt über 50 Jahre Erfahrung als Logistikdienstleister für den Versandhandel.
                                </p>
                                <p>
                                    Rund 180 Mitarbeiter sind im Hauptsitz des Unternehmens im westfälischen Löhne
                                    tätig. Von hier aus werden alle Prozesse und Leistungen in Deutschland, Österreich,
                                    der Schweiz und den Niederlanden gesteuert.
                                </p>
                                <p>
                                    Durch 55 deutsche Standorte sowie die Logistiknetzwerke unserer internationalen
                                    Partner wird flächendeckend ein kundenorientiertes und zuverlässiges
                                    Leistungsangebot garantiert.
                                </p>
                            </div>
                        </div>
                        <div>
                            <div className="has-text-primary facts">
                                <div className="columns">
                                    <div className="column">
                                        <div className="is-flex">
                                            <figure className="image">
                                                <img alt="Schwinge" src="/assets/about/Schwinge.png"/>
                                            </figure>
                                            <span>HUB, Warehouse und Unternehmenszentrale in Löhne</span>
                                        </div>

                                    </div>
                                    <div className="column">
                                        <div className="is-flex">
                                            <figure className="image">
                                                <img alt="Möbel und Großgeräte"
                                                     src="/assets/about/Möbel und Großgeräte.png"/>
                                            </figure>
                                            <span>Rund sieben Millionen Sendungen pro Jahr</span>
                                        </div>

                                    </div>

                                </div>
                                <div className="columns">
                                    <div className="column">
                                        <div className="is-flex">
                                            <figure className="image">
                                                <img alt="HUB" src="/assets/about/HUB.png"/>
                                            </figure>
                                            <span>HUB und Warehouse in Ansbach sowie in Mosina (Polen)</span>
                                        </div>
                                    </div>
                                    <div className="column">
                                        <div className="is-flex">
                                            <figure className="image">
                                                <img alt="Depot" src="/assets/about/Depot.png"/>
                                            </figure>
                                            <span>55 Auslieferdepots in Deutschland</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="columns">
                                    <div className="column">
                                        <div className="is-flex">
                                            <figure className="image">
                                                <img alt="Kunde" src="/assets/about/Kunde.png"/>
                                            </figure>
                                            <span>Rund 5.000 Beschäftigte (inkl. Vertragspartner)</span>
                                        </div>
                                    </div>
                                    <div className="column">
                                        <div className="is-flex">
                                            <figure className="image">
                                                <img alt="Depotstruktur" src="/assets/about/Depotstruktur.png"/>
                                            </figure>
                                            <span>Ausliefergebiete: Deutschland, Österreich, Schweiz, Niederlande</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="">
                                <h2 className="has-text-weight-bold is-size-6">Großer Service für große Sendungen</h2>
                                <p>
                                    Wir bieten Empfängern von Möbeln und Großgeräten eine Vielzahl an Services. Neben
                                    dem Live-Tracking am Liefertag kündigen wir die Sendung telefonisch eine Stunde vor
                                    der Auslieferung an. Wir bringen die Ware mit zwei Mitarbeitern bis an den
                                    gewünschten Ort und entsorgen das Verpackungsmaterial.
                                </p>
                                <p>
                                    Weitere optionale Services wie die Montage von Möbeln und die Installation von
                                    Elektrogroßgeräten runden das Serviceangebot für den Warenempfänger ab.
                                </p>
                            </div>
                            <div className="columns is-centered">
                                <figure className="has-text-centered image is-three-fifths-desktop">
                                    <video className="has-ratio" width="600" height="240" controls controlsList='nodownload'
                                           onContextMenu={(e) => e.preventDefault()}
                                           poster="https://ncc-portal-assets.s3.eu-central-1.amazonaws.com/videos/preview.png">
                                        <source
                                            src="https://ncc-portal-assets.s3.eu-central-1.amazonaws.com/videos/2018_Hermes+vjsual+2_ohne+T%C3%9CV.mp4"
                                            type="video/mp4"/>
                                    </video>
                                </figure>
                            </div>
                        </div>
                        <div style={{marginBottom: '4em'}}>
                            <div>
                                <h2 className="has-text-weight-bold is-size-6">Wir suchen helle Köpfe</h2>
                                <figure className="image">
                                    <img alt="Karriere" src="/assets/about/Karriere.jpg"/>
                                </figure>
                                <p>
                                    Um in einem dynamischen und stark wachsenden Marktumfeld erfolgreich zu sein, suchen
                                    wir stets motivierte und engagierte Mitarbeiter.
                                </p>
                                <p>
                                    Wir bieten interessante Jobs und eine attraktive Perspektive für die berufliche
                                    Entwicklung. Werde ein Teil des Teams und bewirb dich jetzt.
                                </p>
                                <p>
                                    Hier geht es zu den <a className="has-text-grey-darker"
                                                           href="https://www.hermesworld.com/jobs-2mh"
                                                           rel="noopener"
                                                           target="_blank">Jobs</a>.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        ]);
    }
}