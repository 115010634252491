import ReactGA from 'react-ga4';
import { CONFIG } from '../../config/config';

export const GA_CATEGORY = {
	USER_DATA: 'USER_DATA',
	DELIVERY_DATE: 'DELIVERY_DATE',
	DROP_OFF: 'DROP_OFF',
	WISH_OPTIONS: 'WISH_OPTIONS',
	NOTIFICATIONS: 'NOTIFICATIONS',
	APPOINTMENT: 'APPOINTMENT',
	SERVICES: 'SERVICES',
	HONEYPOT: 'HONEYPOT',
	CUSTOMER_SURVEY: 'CUSTOMER_SURVEY',
};

export const GA_ACTION = {
	WISH_OPTION_SELECT: 'WISH_OPTION_SELECT',
	DEPOT_MAIL_CONFIRM: 'DEPOT_MAIL_CONFIRM',
	DOD_CONFIRM: 'DOD_CONFIRM',
	USER_DATA_CONFIRM: 'USER_DATA_CONFIRM',
	DROP_OFF_CONFIRM: 'DROP_OFF_CONFIRM',
	DROP_OFF_CANCEL: 'DROP_OFF_CANCEL',
	NOTIFICATIONS_CONFIRM: 'NOTIFICATIONS_CONFIRM',
	NOTIFICATIONS_EMAIL_CHANGE: 'NOTIFICATIONS_EMAIL_CHANGE',
	NOTIFICATIONS_CANCEL: 'NOTIFICATIONS_CANCEL',
	APPOINTMENT_CONFIRMATION: 'APPOINTMENT_CONFIRMATION',
	SERVICE_BOOKED: 'SERVICE_BOOKED',
	SERVICE_SELECT: 'SERVICE_SELECT',
	SERVICE_CHECKBOX_SELECT: 'SERVICE_CHECKBOX_SELECT',
	SERVICE_CATEGORY_SELECT: 'SERVICE_CATEGORY_SELECT',
	HONEYPOT_FILLED: 'HONEYPOT_FILLED',
	CUSTOMER_SURVEY_OPENED: "CUSTOMER_SURVEY_OPENED",
	CUSTOMER_SURVEY_CLOSED: "CUSTOMER_SURVEY_CLOSED",
};

export const GA_LABEL = {
	NEW_NUMBER_SAVED: 'NEW_NUMBER_SAVED',
	NEW_EMAIL_SAVED: 'NEW_EMAIL_SAVED',
	DOD_DATE_BOOKED: 'DELIVERY_DATE_DOD_BOOKED',
	DEPOT_MAIL_REQUESTED: 'DELIVERY_DATE_DEPOT_MAIL_REQUESTED',
	DROP_OFF_CONFIRMED: 'DROP_OFF_CONFIRMED',
	DROP_OFF_CANCELED: 'DROP_OFF_CANCELED',
	NOTIFICATIONS_CONFIRMED: 'NOTIFICATIONS_CONFIRMED',
	NOTIFICATIONS_EMAIL_CHANGED: 'NOTIFICATIONS_EMAIL_CHANGED',
	NOTIFICATIONS_CANCELED: 'NOTIFICATIONS_CANCELED',
	APPOINTMENT_CONFIRMED: 'APPOINTMENT_CONFIRMED',
	APPOINTMENT_CONFIRMATION_CLOSED: 'APPOINTMENT_CONFIRMATION_CLOSED',
	HONEYPOT_FILLED: 'HONEYPOT_FILLED',
	CUSTOMER_SURVEY: 'CUSTOMER_SURVEY',
};

/**
 * Event - Add custom tracking event.
 * Please also update Documentation_GA.md if smth changes
 * @param {string} category
 * @param {string} action
 * @param {string} label
 */
export const GAEvent = (category, action, label) => {
	ReactGA.event({
		category: category,
		action: action,
		label: label
	});
};

export const initGA = () => {
	ReactGA.initialize(CONFIG.GA_CODE);
	ReactGA.set({ anonymizeIp: true });
	GAPageView(window.location.pathname + window.location.search)
};

/**
 * GAPageView - Add page view event.
 * @param {string} path
 */
export const GAPageView = (path) => {
	ReactGA.send({ hitType: 'pageview', page: path });
};