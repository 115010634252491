import { h, Component } from 'preact';
import { Link } from 'preact-router/match';

export default class Header extends Component {

	state = {
		isActive: false
	}

	_toggleMenu = () => {
		this.setState({ isActive: !this.state.isActive });
	}

	render({ current_page }, { isActive }) {

		const isActiveClass = isActive ? 'is-active' : '';

		const active_page = current_page.indexOf('tracking') > 0 ? 'has-background-light' : '';

		return (
			<header>
				<div className="container is-fluid">
					<nav class="navbar" role="navigation" aria-label="main navigation">
						<div className="navbar-brand">
							<a role="button" className={`navbar-burger burger ${isActiveClass}`} aria-label="menu" aria-expanded="false"
							   data-target="navbarBasic" onClick={this._toggleMenu}>
								<span aria-hidden="true"></span>
								<span aria-hidden="true"></span>
								<span aria-hidden="true"></span>
							</a>
							<a className="navbar-item logo" href="/" onClick={() => this.setState({ isActive: false })}>
								<img src="/assets/HES_weiss.png" alt="Logo HES"/>
							</a>
						</div>

						<div className={`navbar-menu ${isActiveClass}`} id="navbarBasic">
							<div className="navbar-end is-size-4 is-size-5-mobile">
								<Link activeClassName="navbar_active_class" className="navbar-item is-size-3" href="/" onClick={this._toggleMenu} style={{display: 'inline'}}><span className="has-icon-font">&lArr;</span></Link>
								<Link activeClassName="navbar_active_class" className="navbar-item" href="/de/kundenservice" onClick={this._toggleMenu}>Kundenservice</Link>
								<Link activeClassName="navbar_active_class" className="navbar-item" href="/de/faq" onClick={this._toggleMenu}>FAQ</Link>
								<Link activeClassName="navbar_active_class" className="navbar-item" href="/de/ueber-uns" onClick={this._toggleMenu}>Über uns</Link>
							</div>
						</div>
					</nav>
				</div>
			</header>
		);
	}
}
