import {Component} from 'preact';
import {PAGE_NAMES} from '../../../config/i18n/de_page_names';
import {Text} from "preact-i18n";

export default class HelpContactDe extends Component {

    itemRefs = {};
    faq_list = [
        {
            key: 'sendungsnummer',
            question: 'Wo finde ich meine Sendungsnummer?',
            answer: `Für viele Versender kündigen wir Ihnen die Lieferung per E-Mail oder SMS an. In dieser Terminankündigung nennen wir Ihnen Ihre Sendungsnummer. Ebenfalls ist es möglich, dass Ihr Versender Ihnen Ihre Sendungsnummer mitteilt, mit der Sie auf der myhes.de Sendungsverfolgungsseite Zugriff auf alle Auftragsdetails erhalten.`
        },
        {
            key: 'liefertermin_aendern',
            question: 'Kann der Liefertermin geändert werden?',
            answer: 'Ja. Sollten Sie den genannten Liefertermin nicht ermöglichen können, rufen Sie dafür die Auftragsdetails auf der myhes.de Sendungsverfolgungsseite auf und nutzen die Funktion „<span class="has-text-weight-bold">Liefertermin ändern</span>“. Das Auslieferdepot wird sich mit Ihnen für die Abstimmung eines neuen Termins in Verbindung setzen oder Sie erhalten – je nach Versender und Serviceumfang – die Möglichkeit, sich direkt einen neuen Liefertermin zu wählen.'
        },
        {
            key: 'abwesend',
            question: 'Was kann ich tun, wenn ich am Tag der Lieferung doch nicht da bin?',
            answer: `Bis 12 Uhr des Vortags des geplanten Liefertermins können Sie die Funktion „Liefertermin ändern“ wie oben beschrieben in Ihrer myhes.de Sendungsverfolgung nutzen. Sie können uns jedoch auch eine <span class="has-text-weight-bold">Abstellgenehmigung</span> erteilen oder eine <span class="has-text-weight-bold">Nachbarschaftsabgabe</span> erlauben. Rufen Sie dafür die Auftragsdetails auf der myhes.de Sendungsverfolgungsseite auf und erteilen Sie uns die entsprechende Genehmigung.`
        },
        {
            key: 'kontakdaten',
            question: 'Wie kann ich meine Kontaktdaten ändern?',
            answer: 'Aktuell können Sie nur Ihre Telefonnummer ändern. Rufen Sie dafür die Auftragsdetails auf der myhes.de Sendungsverfolgungsseite auf und nutzen Sie die Funktion „<span class="has-text-weight-bold">Kontaktdaten ändern</span>“. Für eine Adressänderung wenden Sie sich bitte an Ihren Versender.'
        },
        {
            key: 'wo_sendung',
            question: 'Wo ist meine Sendung?',
            answer: `Anhand der Informationen auf Ihrer Sendungsverfolgungsseite können Sie den Status Ihrer Lieferung einsehen. Am Tag der Lieferung: In den Auftragsdetails zu Ihrer Lieferung finden Sie das Live-Tracking. Hier können Sie am Tag der Zustellung sehen, wo sich das Lieferteam mit Ihrer Sendung aktuell befindet und zu welcher Zeit etwa Ihre Zustellung erfolgt.`
        },
        {
            key: 'umleitung',
            question: 'Ich bin umgezogen. Kann ich meine Sendung an eine andere Adresse umleiten?',
            answer: `Leider ist dies zurzeit noch nicht möglich. Wenden Sie sich in diesem Fall bitte an Ihren Versender. Eventuell müssen Sie die Lieferung stornieren und erneut mit Ihrer aktuellen Lieferadresse bestellen.`
        },
        {
            key: 'transport_dauer',
            question: 'Wie lange dauert der Transport meiner Sendung?',
            answer: 'Bitte entnehmen Sie die voraussichtliche Lieferzeit der Artikelbeschreibung Ihres Versenders. Das für Sie zuständige Auslieferdepot wird Sie dann in Kürze per SMS, E-Mail, Telefon oder Brief über den geplanten Liefertermin informieren. Sobald der Liefertermin geplant wurde, kann dieser zudem in den Auftragsdetails der myhes.de Sendungsverfolgungsseite entnommen werden.'
        },
        {
            key: 'lieferstatus',
            question: 'Wo ist meine Sendung? Wie kann ich den Lieferstatus prüfen?',
            answer: `Anhand der Auftragsdetails auf der myhes.de Sendungsverfolgungsseite können Sie den Status Ihrer Lieferung einsehen. Am Tag der Lieferung steht Ihnen unser <span class="has-text-weight-bold">Live Tracking</span> in den Auftragsdetails zu Ihrer Lieferung auf der myhes.de Sendungsverfolgungsseite zur Verfügung. Hier können Sie ab dem Start der Liefertour in Echtzeit sehen, wo sich das Lieferteam mit Ihrer Sendung aktuell befindet und zu welcher Zeit Ihre Zustellung erfolgt. Dabei grenzt sich das vorausberechnete Lieferzeitfenster schrittweise bis auf 10 Minuten ein.`
        },
        {
            key: 'retoure_wie',
            question: 'Wie kann ich eine Retoure veranlassen?',
            answer: `Kontaktieren Sie in diesem Fall bitte Ihren Versender. Dieser wird einen Abholauftrag bei uns platzieren. Das Hermes-Team holt die Retoure dann bei Ihnen nach vorheriger Terminankündigung ab.`
        },
        {
            key: 'schaden',
            question: 'Meine Sendung ist beschädigt. Was kann ich tun?',
            answer: `Ist die Ware bei der Lieferung offensichtlich beschädigt, lassen Sie dies vom Lieferteam dokumentieren. Wenn Sie die Ware auf keinen Fall behalten möchten, können Sie die Annahme verweigern, so dass die Sendung automatisch an den Versender retourniert wird. Für die weitere Abstimmung setzen Sie sich danach bitte mit Ihrem Versender in Verbindung. \nWird die Beschädigung erst zu einem späteren Zeitpunkt offensichtlich, wenden Sie sich bitte an Ihren Versender, um die weitere Vorgehensweise zu besprechen. Ein eventueller Retourenauftrag wird in diesem Fall vom Versender beim Hermes Einrichtungs Service platziert.
`
        },
        {
            key: 'sendungsdaten',
            question: 'Woher bekomme ich meine Sendungsdaten?',
            answer: `Für viele Versender kündigen wir Ihnen die Lieferung per E-Mail oder SMS an. In dieser Terminankündigung befindet sich ein Link direkt zu Ihrer Sendungsverfolgung mit Ihren Sendungsdaten. \nEbenfalls ist es möglich, dass Ihr Versender Ihnen Ihre Sendungsnummer mitteilt, mit der Sie auf der myhes.de Sendungsverfolgungsseite Zugriff auf alle Auftragsdetails erhalten. 
`
        },
        {
            key: 'fragen',
            question: 'Ich habe Fragen zur Lieferung, Abholung oder einen Service des Hermes Einrichtungs Service (HES). An wen kann ich mich wenden?',
            answer: `Die Kontaktdaten des für Sie zuständigen Auslieferdepots finden Sie in den Auftragsdetails auf der myhes.de Sendungsverfolgungsseite. Rufen Sie Ihre Sendungsdaten anhand Ihrer Sendungsnummer auf.
`
        },
        {
            key: 'wohnung',
            question: 'Wird meine Ware in die Wohnung geliefert?',
            answer: `Wir liefern Ihre Ware bis an den Wunschplatz, egal ob in den Keller oder den dritten Stock. Bei einigen Versendern können Sie weitere Services zubuchen. Wir schließen Ihre Geräte an, nehmen Altgeräte mit oder montieren Ihre Möbel. Wenden Sie sich an Ihren Versender, wenn Sie sich für entsprechende Services interessieren. \nSollten Sie entsprechende Services bestellt haben, teilen Sie uns bitte etwaige Besonderheiten, z.B. schmale Durchgänge, steile Treppen etc. mit, so dass die Lieferung reibungslos verläuft. Die Kontaktdaten Ihres Auslieferdepots finden Sie in den Auftragsdetails auf der myhes.de Sendungsverfolgungsseite. 
`
        },
        {
            key: 'versuche',
            question: 'Wie oft versucht das Hermes-Team meine Sendung zuzustellen?',
            answer: `Sollten Sie doch einmal nicht zu Hause sein, wird das Hermes-Team Sie erneut wegen eines neuen Liefertermins kontaktieren. Dies erfolgt bis zu drei Mal, bevor die Sendung zurück an den Versender geht. Sie können uns jedoch eine <span class="has-text-weight-bold">Abstellgenehmigung</span> erteilen, so dass Sie in jedem Fall Ihre Lieferung erhalten. Rufen Sie dafür die Auftragsdetails auf der myhes.de Sendungsverfolgungsseite auf und erteilen Sie uns die entsprechende Genehmigung. `
        },
        {
            key: 'hes',
            question: 'Gibt es einen Unterschied zwischen Hermes bzw. Hermes Germany und Hermes Einrichtungs Service (HES)?',
            answer: `Sie wundern sich vielleicht, wieso der Hermes Einrichtungs Service (HES) Ihre Lieferung zustellt. Die Zwei-Mann-Teams des Hermes Einrichtungs Service beliefern Endkunden mit Möbeln, Elektrogroßgeräten und anderen schweren oder sperrigen Gütern. HES ist ein eigenständig arbeitendes Schwesterunternehmen der Hermes Germany, die für alle Paketlieferungen bis max. 31,5 kg zuständig ist. Wollen Sie mehr über HES und die anderen Hermes Gesellschaften erfahren? Dann besuchen Sie doch die Seite „<a href="/de/ueber-uns" target="_top" class="has-text-grey-darker">Über uns</a>“.`,
        }
    ];

    componentDidMount() {
        document.title = PAGE_NAMES.HELP + ' - ' + PAGE_NAMES.HES;
        const hash = document.location.hash;
        if (hash && this.itemRefs[hash]) {
            this.itemRefs[hash].scrollIntoView();
        }
    }

    render() {
        return ([
            <main className="container tracking-details static-content">
                <div className="has-text-centered is-hidden-touch">
                    <section className="hero">
                        <div className="hero-body">
                            <div className="tracking-container">
                                <h1 className="title has-text-white is-size-2">
                                    <Text id={`faq.header`}/>
                                </h1>
                            </div>
                        </div>
                    </section>
                </div>
                <div className="columns main-container">
                    <div
                        className="column is-10-desktop is-offset-1-desktop">
                        <div className="has-text-grey-darker has-text-centered is-hidden-desktop">
                            <section className="hero">
                                <div className="hero-body">
                                    <div className="tracking-container">
                                        <h1 className="title is-size-2-tablet is-size-4-mobile">
                                            <Text id={`faq.header`}/>
                                        </h1>
                                    </div>
                                </div>
                            </section>
                        </div>
                        <h3 className="has-text-grey-darker" style={{marginBottom: '1em'}}>
                            <Text id="faq.subheader"/>
                        </h3>
                        <div className="faq-list">
                            <ul>
                                {this.faq_list.map(entry => {
                                    return (
                                        <li>
                                            <a href={`#${entry.key}`} className="button is-text has-text-primary">
                                                {entry.question}
                                            </a>
                                        </li>
                                    )
                                })}
                            </ul>
                        </div>

                        <div className="faq-items">
                            {this.faq_list.map(entry => {
                                return (
                                    <div className="faq-item">
                                        <a name={entry.key} ref={el => (this.itemRefs['#' + entry.key] = el)}/>
                                        <h3>{entry.question}</h3>
                                        <p dangerouslySetInnerHTML={{__html: entry.answer}}/>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
            </main>
        ]);
    }
}