import {Component} from 'preact';
import {PAGE_NAMES} from '../../../config/i18n/de_page_names';
import {Text} from "preact-i18n";

export default class CustomerServiceDe extends Component {

    componentDidMount() {
        document.title = PAGE_NAMES.CUSTOMER_SERVICE + ' - ' + PAGE_NAMES.HES;
    }

    render() {
        return ([
            <main className="container tracking-details static-content">
                <div className="has-text-centered is-hidden-touch">
                    <section className="hero">
                        <div className="hero-body">
                            <div className="tracking-container">
                                <h1 className="title has-text-white is-size-2">
                                    <Text id={`customer_service.header`}/>
                                </h1>
                            </div>
                        </div>
                    </section>
                </div>
                <div className="columns main-container" style={{minHeight: '70vh'}}>
                    <div
                        className="column is-10-desktop is-offset-1-desktop">
                        <div className="has-text-grey-darker has-text-centered is-hidden-desktop">
                            <section className="hero">
                                <div className="hero-body">
                                    <div className="tracking-container">
                                        <h1 className="title is-size-2-tablet is-size-4-mobile">
                                            <Text id={`customer_service.header`}/>
                                        </h1>
                                    </div>
                                </div>
                            </section>
                        </div>

                        <div className="faq-item" style={{marginBottom: '4em'}}>
                            Sie haben Fragen zu einer Lieferung, Abholung oder Servicetermin des Hermes Einrichtungs Service?
                            <br/>
                            Dann finden Sie die Kontaktdaten des für Sie zuständigen Auslieferdepots auf der Sendungsverfolgungsseite.
                            Rufen Sie die Sendungsverfolgung für Ihre Lieferung anhand Ihrer Sendungsnummer auf.
                            <br/>
                            <br/>
                            Haben Sie vom Hermes Einrichtungs Service eine Terminankündigung per E-Mail oder SMS erhalten?
                            <br/>
                            Dann klicken Sie auf den in dieser Nachricht aufgeführten Link. Dieser leitet Sie direkt auf die Sendungsverfolgungsseite für Ihre Lieferung.
                        </div>
                    </div>
                </div>
            </main>
        ]);
    }
}