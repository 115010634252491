import { Text } from 'preact-i18n';
import { Component } from "preact";

export default class Footer extends Component {

    state = {
        bannerHeight: 0
    };

    componentDidUpdate(previousProps, previousState, snapshot) {
        if (previousProps !== this.props) {
            if (!this.props.cookie_banner_present) {
                this.setState({bannerHeight: 0})
            } else  {
                this.setState({bannerHeight: document.getElementById('cookie-banner').offsetHeight + 10})
            }
        }

    }
    componentDidMount() {
        if (this.props.cookie_banner_present && document.getElementById('cookie-banner')) {
            this.setState({bannerHeight: document.getElementById('cookie-banner').offsetHeight + 10})
        }
    }

    render({}, {bannerHeight}) {

        return (
            <footer className="footer">
                <div className="container" style={{paddingBottom: bannerHeight + 'px'}}>
                    <div className="columns">
                        <div className="column magic-footer">
                            <div>
                                <div className="wrapper">
                                    <div>
                                        <h2 className="has-text-grey is-uppercase">Service</h2>
                                        <a href="/de/kundenservice"
                                           rel="noopener noreferrer"
                                        >
                                            <Text id="footer.navigation.service"/>
                                        </a>
                                        <a href="/de/faq"
                                           rel="noopener noreferrer"
                                        >
                                            <Text id="footer.navigation.faq"/>
                                        </a>
                                    </div>
                                    <div>
                                        <h2 className="has-text-grey is-uppercase">Allgemein</h2>
                                        <a href="https://www.hes.de/de/impressum/"
                                           target="_blank"
                                           rel="noopener noreferrer"
                                        >
                                            <Text id="footer.navigation.imprint">Missing i18n</Text>
                                        </a>
                                        <a href="https://www.hes.de/de/ncc"
                                           target="_blank"
                                           rel="noopener noreferrer"
                                        >
                                            <Text id="footer.navigation.privacy">Missing i18n</Text>
                                        </a>
                                        <a href="https://www.hes.de/de/default/"
                                           target="_blank"
                                           rel="noopener noreferrer"
                                        >
                                            <Text id="footer.navigation.security">Missing i18n</Text>
                                        </a>
                                    </div>
                                </div>
                                <div className="columns is-flex is-centered">

                                        <a column href="https://g.page/r/Ce_N1Ilgjqk7EAE"
                                           target="_blank"
                                           rel="noopener noreferrer"
                                        >
                                            <img width="189" alt="Google evaluation" src="/assets/googleEvaluation.png" />
                                        </a>

                                </div>
                                <span>©{new Date().getFullYear()} HERMES Einrichtungs Service GmbH & Co. KG</span>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>)
    }

}
